<template>
  <div class="footer">
    <a-divider />
    <footer>
      <p>
        <span>地址：北京市丰台区菜户营天伦北里小区7号楼</span>
        <span>邮编：100054</span>
        <span>电话：010-63505117</span>
        <span @click="handleJump" style="cursor: pointer;"
          >IPC备案编号：京ICP 备15067162号-6</span
        >
        <span>京公网安备 11010602060200</span>
        <!-- <span 
          >京ICP 备15067162号-6</span
        > -->
      </p>
      <p>
        <span>国家卫生健康委人口文化发展中心版权所有</span>
        <span>技术支持：杭州医养网络科技公司</span>
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    handleJump() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  margin: 20px 0;
  text-align: center;
  height: 86px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 22px;
  color: #212223;
  span {
    margin: 0 8px;
  }
}
</style>
