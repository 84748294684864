<template>
  <div class="layout-container">
    <Header></Header>
    <div class="menus">
      <span
        v-for="item in menus"
        :key="item.categoryId"
        @mousemove="handleMove(item)"
        @click="handleToggle(item)"
        :class="['menu-item', active == item.categoryId ? 'active' : '']"
        >{{ item.name }}</span
      >
    </div>
    <div class="menus-children">
      <span
        v-for="it in menusChildrenList"
        :key="it.categoryId"
        @click="handleChild(it)"
        class="menu-children-item"
        >{{ it.name == '星级养老院' ? '机构展示' : it.name }}</span
      >
    </div>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { getMenuList } from '@/api'
import { mapMutations } from 'vuex'
export default {
  name: 'Layout',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      active: '',
      menus: [
        {
          homePath: 'index',
          path: '/',
          name: '首页'
        }
      ],
      menusChildrenList: []
    }
  },
  created() {
    this.getMenusList()
  },
  methods: {
    ...mapMutations({
      setLayoutTitle: 'layout/setLayoutTitle'
    }),
    async getMenusList() {
      const { data } = await getMenuList()
      this.menus = [...this.menus, ...data]
    },
    handleToggle(item) {
      if (item.homePath == 'index') {
        this.menusChildrenList = []
        this.$router.push({
          path: '/'
        })
        this.setLayoutTitle('')
        return
      }
      if (item.skipType == 0) {
        this.$router.push({
          path: '/tabpage',
          query: {
            categoryId: item.categoryId
          }
        })
        this.setLayoutTitle(item.name)
      } else {
        // 跳转外链
        if (item.openNewPage == 0 && item.path) {
          //新页面打开
          window.open(item.path, '_blank')
        } else {
          if (item.path) {
            window.open(item.path, '_self')
          }
        }
      }
    },
    // 鼠标经过
    handleMove(item) {
      this.active = item.categoryId
      this.menusChildrenList = (item.children && [...item.children]) || []
    },
    handleChild(item) {
      console.log(item)
      if (item.name == '星级养老院' || item.categoryId == 1055) {
        this.$router.push({
          path: '/organizationList'
        })
        this.setLayoutTitle(item.name)
        return
      }
      if (item.skipType == 0) {
        this.$router.push({
          path: '/tabpage',
          query: {
            categoryId: item.categoryId
          }
        })
        this.setLayoutTitle(item.name)
      } else {
        // 跳转外链
        if (item.path) {
          window.open(item.path)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-container {
  span {
    display: inline-block;
  }
  .menus {
    height: 60px;
    padding: 0 0px;
    background: #1766aa;
    width: 100%;
    display: flex;
    .menu-item {
      // width: 176px;
      flex: 1;
      // padding: 0 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;
    }
    .active {
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .menus-children {
    padding: 0 160px;
    height: 52px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    .menu-children-item {
      width: 176px;
      font-size: 16px;
      text-align: center;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #141f33;
      line-height: 52px;
      &:hover {
        color: #5e7ed4;
      }
    }
  }
}
</style>
