<template>
  <div class="Sponsor">
    <p>主办单位：国家卫生健康委人口文化发展中心</p>
    <p>支持单位:中国卫生信息与健康医疗大数据学会智慧医养护专业委员会</p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.Sponsor {
  text-align: center;
  margin-bottom: 52px;
  p {
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #212223;
    line-height: 25px;
  }
}
</style>
