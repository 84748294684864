<template>
  <div>
    <a-modal
      v-model="visibled"
      :title="title"
      ok-text="确认"
      cancel-text="取消"
      @ok="handleSubmit"
      @cancel="hideModal"
    >
      <a-form-model
        ref="rform"
        :model="formData"
        @submit="handleSubmit"
        @submit.native.prevent
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="账号">
          <a-input v-model="formData.username" placeholder="账号">
            <a-icon
              slot="prefix"
              type="user"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="密码">
          <a-input
            v-model="formData.password"
            type="password"
            placeholder="密码"
          >
            <a-icon
              slot="prefix"
              type="lock"
              style="color: rgba(0, 0, 0, 0.25)"
            />
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { userRegister, userLogin } from '@/api/user'
export default {
  props: {
    visible: {
      type: Boolean,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  watch: {
    visible(val) {
      this.visibled = val
    },
    type(val) {
      if (val === 'reg') {
        this.title = '注册账号'
      } else {
        this.title = '登录账号'
      }
    }
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      visibled: false,
      username: '',
      title: '登录账号',
      formData: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    hideModal() {
      this.$refs.rform.resetFields()
      this.formData = {
        username: '',
        password: ''
      }
      this.$emit('close')
    },
    async handleSubmit() {
      if (this.title === '注册账号') {
        const { data, msg, code } = await userRegister(this.formData)
        try {
          if (code == 200) {
            this.$message.success(msg)
            this.formData = {
              username: '',
              password: ''
            }
            this.$emit('close')
          } else {
            this.$message.error(msg)
          }
        } catch (error) {
          this.$message.error(error)
        }
      } else {
        const { data, msg, code } = await userLogin(this.formData)
        try {
          if (code == 200) {
            sessionStorage.setItem('username', data)
            this.$message.success(msg)
            this.formData = {
              username: '',
              password: ''
            }
            this.$emit('close')
            location.reload()
          } else {
            this.$message.error(msg)
          }
        } catch (error) {
          this.$message.error(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
