<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <!-- 路由占位符 -->
      <router-view></router-view>
      <!-- <iframe v-else id="iframe" src="http://192.192.45.242:82/gmjkylw/h5" style="height: calc(100% )" width="100%" frameborder="0"></iframe> -->
    </div>
  </a-config-provider>
</template>
<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
  data() {
    return {
      zh_CN,
      isphone:true,
    };
  },
  mounted(){
    if (this.isMobile()) {
      this.isphone=true
      console.log("shoujiduan");
      const downloadLink = window.document.createElement('a');
      // downloadLink.href ='http://192.192.45.242:82/gmjkylw/h5'
      downloadLink.href ='https://www.gmjkyl.org/mobile/#/index'
      downloadLink.target ='_self',
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      this.isphone=false
      console.log("pc");
    }
  },
  methods: {
    //判断手机端还是PC端
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  max-width: 1920px;
  max-height: 1080px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}