import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { createDownloadLink } from '@/utils'
import { message } from 'ant-design-vue'
axios.interceptors.request.use(
  config => {
    // const accesstoken = store.getters.token && getToken()
    // // 如果token是JSESSIONID,把JSESSIONID 带到url 后面
    // if (accesstoken && /^JSESSIONID:/.test(accesstoken)) {
    //   if (
    //     config.params &&
    //     Object.prototype.toString.call(config.params) === '[object Object]'
    //   ) {
    //     config.params.JSESSIONID = accesstoken.substring(11)
    //   } else {
    //     config.params = { JSESSIONID: accesstoken.substring(11) }
    //   }
    // }

    return config
  },
  error => {
    return error
  }
)

axios.defaults.transformRequest = function(data, headers) {
  const accesstoken = store.getters.token && getToken()
  // eslint-disable-next-line no-prototype-builtins
  if (accesstoken && !headers.hasOwnProperty('access_token')) {
    headers.access_token = accesstoken.replace(/^JSESSIONID:/, '') // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  if (
    headers['Content-Type'] === 'application/x-www-form-urlencoded' &&
    data &&
    Object.prototype.toString.call(data) === '[object Object]'
  ) {
    data = qs.stringify(data)
  } else if (headers['Content-Type'] === 'application/json') {
    data = JSON.stringify(data)
  }
  return data
}
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // api的base_url
  timeout: 15000 // 请求超时时间
})

// respone拦截器
service.interceptors.response.use(
  res => {
    if (res.status === 200) {
      return Promise.resolve(res.data)
    } else {
      message.error('网络请求超时，请稍后再试')
      return Promise.reject(res)
    }
  },
  err => {
    console.log('网络异常')
    return Promise.reject(err)
  }
)

export function request(_param) {
  const {
    method = 'get',
    // `responseType` 表示服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
    responseType = 'json', // 默认的
    headers = {},
    url = '',
    params,
    data,
    ...otherData
  } = _param

  if (!url) {
    return new Promise((resolve, reject) => {
      reject(new Error('url is null'))
    })
  }
  const _method = method.toLowerCase()
  if (_method === 'get') {
    return axios({
      responseType,
      url,
      baseURL: process.env.VUE_APP_API_URL,
      headers,
      method,
      params: params || data || otherData
    })
  }

  if (_method === 'delete' || _method === 'put') {
    if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
      headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=UTF-8'
    }
    return axios({
      responseType,
      url,
      baseURL: process.env.VUE_APP_API_URL,
      headers,
      method,
      data,
      params: params || data || otherData
    })
  }

  if (_method === 'post') {
    if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
      headers['Content-Type'] =
        'application/x-www-form-urlencoded;charset=UTF-8'
    }
    if (params && data) {
      return axios({
        responseType,
        url,
        headers,
        baseURL: process.env.VUE_APP_API_URL,
        method,
        params,
        data
      })
    } else {
      const { start, limit, ...resetData } = otherData
      return axios({
        responseType,
        url,
        headers,
        baseURL: process.env.VUE_APP_API_URL,
        method,
        params: params || { start, limit },
        data: data || resetData
      })
    }
  }
}
export function download(_param) {
  // eslint-disable-next-line no-prototype-builtins
  if (!_param.hasOwnProperty('responseType')) {
    _param.responseType = 'blob'
  }
  return request(_param)
    .then(res => {
      if (res.status === 200 && res.request.readyState === 4) {
        createDownloadLink(res, _param.params)
      } else {
        console.log('message: 下载失败')
      }
      return res
    })
    .catch(error => {
      console.log(error)
      return error
    })
}
export default service
