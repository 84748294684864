<template>
  <div class="container">
    <div class="home-img">
      <Carousel-G :data="fullScreenImg"></Carousel-G>
    </div>
    <div class="tabs">
      <span
        v-for="it in normalLink"
        :key="it.linkUrl"
        @click="handleLink(it)"
        class="tab-item"
      >
        {{ it.linkName }}
      </span>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="swiper-container">
          <Carousel :data="smallScreenImg"></Carousel>
        </div>
        <div class="">
          <ArtrcleMoudle
            :class="`left-${index}`"
            v-for="(item, index) in listData1"
            :data="item"
            :key="item.articleId"
          ></ArtrcleMoudle>
        </div>
      </div>
      <div class="content-center">
        <div>
          <ArtrcleMoudle
            :class="`center-${index}`"
            v-for="(item, index) in listData2"
            :data="item"
            :key="item.articleId"
          ></ArtrcleMoudle>
        </div>
      </div>
      <div class="content-right">
        <!-- 视频列表 -->
        <VideoPlayer class="right-video"></VideoPlayer>
        <div>
          <ArtrcleMoudle
            :class="`right-${index}`"
            v-for="(item, index) in listData3"
            :data="item"
            :key="item.articleId"
          ></ArtrcleMoudle>
        </div>
      </div>
    </div>
    <!-- 模块 -->
    <Moudle class="moudle"></Moudle>
    <!-- 友情链接 -->
    <Blogroll></Blogroll>
    <!-- 主办单位 -->
    <Sponsor></Sponsor>
  </div>
</template>

<script>
import ArtrcleMoudle from '@/components/ArticleMoule/ArticleList'
import Moudle from '@/components/Moulde'
import Blogroll from '@/components/Blogroll'
import Sponsor from '@/components/Sponsor'
import VideoPlayer from '@/components/VideoPlayer'
import Carousel from '@/components/Carousel'
import CarouselG from '@/components/Carousel/index1'
import { getSlideshow, getLinkList, getArticleList } from '@/api'
const list = [
  { categoryName: '热点关注', sort: 1 },
  { categoryName: '康养旅居', sort: 2 },
  { categoryName: '康养学社', sort: 3 },
  { categoryName: '大政要闻', sort: 4 },
  { categoryName: '行业动向', sort: 5 },
  { categoryName: '业界峰会', sort: 6 },
  { categoryName: '院长健谈', sort: 7 },
  { categoryName: '公益行动', sort: 8 },
  { categoryName: '居家养老', sort: 9 }
]
export default {
  name: 'index',
  components: {
    Carousel,
    ArtrcleMoudle,
    Moudle,
    Blogroll,
    Sponsor,
    VideoPlayer,
    'Carousel-G': CarouselG
  },
  created() {
    this.getLinkList()
    this.getSlideshowList()
    this.getArticleList()
  },
  data() {
    return {
      list,
      normalLink: [], //普通链接
      blogrollLink: [], //友情链接
      fullScreenImg: [], // 全屏
      smallScreenImg: [], //
      articleList: [],
      listData1: [],
      listData2: [],
      listData3: []
    }
  },
  computed: {},
  methods: {
    handleLink(data) {
      if (data.linkUrl) {
        window.open(data.linkUrl)
      }
    },
    async getLinkList() {
      const { data, code } = await getLinkList()
      try {
        if (code === 200) {
          this.normalLink = [...data[0]]
          this.blogrollLink = data[1]
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 轮播图
    async getSlideshowList() {
      const { data, code } = await getSlideshow()
      try {
        if (code === 200) {
          this.fullScreenImg = [...data[0]]
          this.smallScreenImg = data[1]
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    //文章列表
    async getArticleList() {
      const { data, code } = await getArticleList()
      try {
        if (code === 200) {
          const list1 = []
          this.list.forEach(item => {
            data.forEach(it => {
              if (item.categoryName == it.categoryName) {
                item = {
                  ...item,
                  ...it
                }
              }
            })
            list1.push(item)
          })
          // const data1 = data.filter(item => {
          //   if (item.categoryName == '康养地产') {
          //     item.sort = 1
          //   }
          //   if (item.categoryName == '康养旅居') {
          //     item.sort = 2
          //   }
          //   if (item.categoryName == '康养学社') {
          //     item.sort = 3
          //   }
          //   if (item.categoryName == '大政要闻') {
          //     item.sort = 4
          //   }
          //   if (item.categoryName == '行业动向') {
          //     item.sort = 5
          //   }
          //   if (item.categoryName == '业界峰会') {
          //     item.sort = 6
          //   }
          //   if (item.categoryName == '院长健谈') {
          //     item.sort = 7
          //   }
          //   if (item.categoryName == '居家养老') {
          //     item.sort = 8
          //   }
          //   return item.categoryName != '健康管理'
          // })
          this.articleList = list1.sort(function(a, b) {
            return a.sort - b.sort
          })
          // console.log(
          //   data.sort(function(a, b) {
          //     return a.sort - b.sort
          //   })
          // )
          const listData = this.$_.chunk(this.articleList, 3)
          console.log(listData, 'dsadas')
          this.listData1 = listData[0]
          this.listData2 = [...listData[1]]
          this.listData3 = listData[2]
        }
      } catch (error) {
        this.$message.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 160px;
  .home-img {
    width: 100%;
    height: 160px;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
  .tabs {
    width: 100%;
    height: 45px;
    background: #f8f8f8;
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    .tab-item {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1766aa;
      line-height: 45px;
      cursor: pointer;
      &:hover {
        color: #5e7ed4;
      }
    }
  }
  .content {
    display: flex;
    width: 100%;
    margin-top: 30px;
    height: 1056px;
    overflow: hidden;
    justify-content: space-between;
    &-left {
      width: 500px;
      .swiper-container {
        // height: 300px;
      }
      .article-moulde1 {
        // height: 400px;
      }
      .article-moulde2 {
        // height: 400px;
      }
      .article-moulde3 {
        // height: 400px;
      }
    }
    &-center {
      width: 500px;
      margin: 0 40px;
    }
    &-right {
      width: 500px;
    }
  }
  .moudle {
    margin-top: 50px;
  }
}

.left-0 {
  height: 190px;
  overflow: hidden;
}
.left-1 {
  height: 282px;
  overflow: hidden;
}
.left-2 {
  height: 264px;
  overflow: hidden;
}
.center-0 {
  height: 488px;
  overflow: hidden;
}
.center-1 {
  height: 284px;
  overflow: hidden;
}
.center-2 {
  height: 284px;
  overflow: hidden;
}
.right-video {
  height: 320px;
  overflow: hidden;
}
.right-0 {
  height: 260px;
  overflow: hidden;
}
.right-1 {
  height: 190px;
  overflow: hidden;
}
.right-2 {
  height: 284px;
  overflow: hidden;
}
</style>
