// 用户相关接口
import request from '@/utils/request'
/**
 * 用户注册
 * @param {*} articleId
 * @returns
 */
export const userLogin = data => {
  return request({
    url: '/gmjkylw_web/user/login',
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json'
    },
    data
  })
}

export const userRegister = data => {
  return request({
    url: '/gmjkylw_web/user/register',
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json'
    },
    data
  })
}
