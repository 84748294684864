<template>
  <div class="blogroll">
    <h3 class="title">友情链接</h3>
    <div class="link">
      <a
        :href="it.linkUrl"
        v-for="(it, index) in blogrollLink"
        :key="index"
        class="tab-item"
        target="_blank"
        >{{ it.linkName }}</a
      >
    </div>
  </div>
</template>

<script>
import { getLinkList } from '@/api'

export default {
  data() {
    return {
      blogrollLink: []
    }
  },
  created() {
    this.getLinkList()
  },
  methods: {
    handleLink(data) {
      if (data.linkUrl) {
        window.open(data.linkUrl)
      }
    },
    async getLinkList() {
      const { data, code } = await getLinkList()
      try {
        if (code === 200) {
          this.blogrollLink = data[1]
        }
      } catch (error) {
        this.$message.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.blogroll {
  margin-bottom: 52px;
  .title {
    margin: 20px 0;
    width: 88px;
    height: 30px;
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff4d4d;
    line-height: 30px;
    white-space: nowrap;
  }
  .link {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    > a {
      margin-right: 100px;
      margin-bottom: 16px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #666666;
      line-height: 22px;
      cursor: pointer;
      &:hover {
        color: #5e7ed4;
      }
    }
  }
}
</style>
