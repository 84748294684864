const state = () => ({
  lyaoutTitle: '' || sessionStorage.getItem('lyaoutTitle')
})
const getters = {
  lyaoutTitle: state => state.lyaoutTitle
}
const mutations = {
  setLayoutTitle(state, lyaoutTitle) {
    sessionStorage.setItem('lyaoutTitle', lyaoutTitle)
    state.lyaoutTitle = lyaoutTitle
  }
}
const actions = {}
export default { state, getters, mutations, actions }
