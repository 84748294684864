<template>
  <div class="layout-container">
    <Header></Header>
    <div class="menus"></div>
    <div class="menus-children"></div>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
  import Footer from "@/components/Footer";
  import Header from "@/components/Header";
  export default {
    name: "Layout",
    components: {
      Header,
      Footer
    },
    data() {
      return {};
    }
  };
</script>

<style lang="scss" scoped>
  .layout-container {
    .menus {
      height: 60px;
      background: #1766aa;
      width: 100%;
      .menu-item {
        width: 176px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .active {
        background: rgba(255, 255, 255, 0.2);
      }
    }
    .menus-children {
      height: 52px;
      width: 100%;
      overflow: hidden;
      .menu-children-item {
        width: 176px;
        font-size: 16px;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #141f33;
        line-height: 52px;
      }
    }
  }
</style>