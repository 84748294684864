<template>
  <div class="moulde-list">
    <div
      class="moulde"
      v-for="(item, index) in list.slice(0, 3)"
      :key="index"
      @click="handleClick(item)"
    >
      <div class="title">{{ item.moduleName }}</div>
      <div class="img-content">
        <img :src="item.moduleImage" alt="" />
        <span class="img-name">
          {{ item.moduleTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getModuleList } from '@/api'
export default {
  name: 'Model',
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getModuleList()
  },
  methods: {
    async getModuleList() {
      const { data, code } = await getModuleList()
      try {
        if (code === 200) {
          this.list = [...data]
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    handleClick(item) {
      if (item.moduleUrl) {
        window.open(item.moduleUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.moulde-list {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 52px;
  .moulde {
    margin-right: 32px;
    .title {
      height: 30px;
      font-size: 30px;
      margin: 20px 0;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ff4d4d;
      line-height: 30px;
    }
    .img-content {
      position: relative;
      cursor: pointer;
      > img {
        width: 500px;
        height: 280px;
        object-fit: cover;
        background: #d8d8d8;
      }
      .img-name {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 500px;
        height: 45px;
        background: rgba(0, 0, 0, 0.2);
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 45px;
        padding-left: 15px;
      }
    }
  }
}
</style>
