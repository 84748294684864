import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import LayoutMenus from '@/components/Layout/LayoutMenus'
import LayoutBlank from '@/components/Layout/LayoutBlank'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: LayoutMenus,
    redirect: '/index',
    children: [
      {
        path: '/index',
        name: 'index',
        component: index
      },
      {
        path: '/tabpage',
        name: 'tabpage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "tabPage" */ '@/views/tabPage/index.vue')
      },
      {
        path: '/listpage',
        name: 'listpage',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "listpage" */ '@/views/listPage/index.vue'
          )
      },
      {
        path: '/detail',
        name: 'detail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "detail" */ '@/views/detail/index.vue')
      },
      {
        path: '/videoplay',
        name: 'videoplay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "VideoPlayer" */ '@/components/VideoPlayer/videoDetail.vue'
          )
      },
      {
        path: '/videolist',
        name: 'videolist',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "VideoPlayer" */ '@/components/VideoPlayer/videoList.vue'
          )
      },
      {
        path: '/organizationList',
        name: 'organizationList',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "organizationList" */ '@/views/organizationList/index.vue'
          )
      },
      {
        path: '/organizationDetail',
        name: 'organizationDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "organizationDetail" */ '@/views/detail/organizationDetail.vue'
          )
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
