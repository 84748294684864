<template>
  <a-carousel arrows autoplay effect="fade">
    <!-- <div slot="prevArrow" :slot-scope="'props'" class="custom-slick-arrow">
      <a-icon type="left" />
    </div>
    <div
      slot="nextArrow"
      :slot-scope="'props'"
      class="custom-slick-arrow"
      style="right: 0px"
    >
      <a-icon type="right" class="icon" />
    </div> -->
    <div class="moulde" v-for="it in data" :key="it.image">
      <div class="img-content">
        <img :src="it.image" alt="" @click="handleClick(it)" />
      </div>
    </div>
  </a-carousel>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  created() {},
  methods: {
    handleClick(it) {
      if (it.url) {
        window.open(it.url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-carousel {
  /deep/ .slick-slide {
    text-align: center;
    width: 100%;
    height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  /deep/ .slick-prev {
    left: 10px;
    z-index: 222;
  }
  /deep/ .slick-next {
    right: 0px;
    z-index: 222;
  }
}

.ant-carousel {
  /deep/ .custom-slick-arrow {
    font-size: 72px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
  }
}
.ant-carousel {
  /deep/.custom-slick-arrow:before {
    display: none;
  }
}
.ant-carousel {
  /deep/ .custom-slick-arrow:hover {
    opacity: 1;
    color: #fff;
  }
}

.ant-carousel {
  /deep/ .slick-slide div {
    color: #fff;
  }
}
.moulde {
  .img-content {
    position: relative;
    cursor: pointer;
    > img {
      width: 100%;
      height: 160px;
      object-fit: cover;
    }
  }
}
</style>
