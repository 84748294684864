import request from '@/utils/request'

/**
 * 文章列表
 * @param {*} data
 * @returns
 */
export const getArticleList = data => {
  return request({
    url: '/gmjkylw_web/index/getArticle',
    method: 'get',
    data
  })
}

/**
 *导航菜单
 */
export const getMenuList = data => {
  return request({
    url: '/gmjkylw_web/index/getPortalMenu',
    method: 'get',
    data
  })
}

/**
 * 轮播图-列表
 * @param {*} data
 * @returns
 */
export const getSlideshow = data => {
  return request({
    url: '/gmjkylw_web/index/getSlideshow',
    method: 'get',
    data
  })
}

/**
 * 视频-列表
 * @param {*} data
 * @returns
 */
export const getVideoList = data => {
  return request({
    url: '/gmjkylw_web/index/getVideo',
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json'
    },
    data
  })
}

/**
 * 模块-列表
 * @param {*} data
 * @returns
 */
export const getModuleList = data => {
  return request({
    url: '/gmjkylw_web/index/getModule',
    method: 'get',
    headers: {
      ['Content-Type']: 'application/json'
    },
    data
  })
}

/**
 * 模块-链接
 * @param {*} data
 * @returns
 */
export const getLinkList = data => {
  return request({
    url: '/gmjkylw_web/index/getLink',
    method: 'get',
    data
  })
}

/**
 * 模块-搜索
 * @param {*} params
 * @returns
 */
export const onSearch = params => {
  return request({
    url: '/gmjkylw_web/index/search',
    method: 'get',
    headers: {
      ['Content-Type']: 'application/json'
    },
    params
  })
}

/**
 * 机构列表
 * @param {*} data
 * @returns
 */
export const getOrganizationList = data => {
  return request({
    url: '/gmjkylw_web/organization/list',
    method: 'post',
    headers: {
      ['Content-Type']: 'application/json'
    },
    data
  })
}

//获取国家标准下级地区数据

/**
 * 机构列表
 * @param {*} parentCode
 * @returns
 */
export const getAreaSubordinateList = (parentCode = 0) => {
  return request({
    url: `/gmjkylw_web/area/subordinate?parentCode=${parentCode}`,
    method: 'get',
    headers: {
      ['Content-Type']: 'application/json'
    }
  })
}
