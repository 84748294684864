<template>
  <div class="video-list">
    <h3 @click="handleTitle">视频 <span class="more">更多>></span></h3>
    <div class="list">
      <div class="cover-list" v-if="coverList.length">
        <div
          class="cover"
          v-for="(it, index) in coverList.slice(0, 2)"
          :key="index"
          @click="handleVideo(it)"
          v-show="it.surfacePlot"
        >
          <div class="cover-video">
            <img :src="it.surfacePlot" alt="" />
            <img src="@/assets/img/player-btn.png" alt="" class="player-btn" />
            <span class="img-name">{{ it.title }}</span>
          </div>
        </div>
      </div>
      <ul>
        <li
          v-for="(it, index) in coverList1"
          :key="index"
          @click="handleVideo(it)"
        >
          {{ it.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getVideoList } from '@/api'
export default {
  data() {
    return {
      coverList: [],
      coverList1: [],
      params: {
        pageNum: 1, //页码
        pageSize: 10 //每页条数
      }
    }
  },
  created() {
    this.getVideoList()
  },
  methods: {
    handleTitle() {
      this.$router.push({
        path: '/videoList'
      })
    },
    async getVideoList() {
      const { data, code } = await getVideoList(this.params)
      try {
        if (code === 200) {
          this.coverList = []
          this.coverList1 = []
          // this.coverList = data.slice(0, 2)
          data.forEach(item => {
            if (item.showFlag == 0) {
              this.coverList.push(item)
            }
          })
          data.forEach(item => {
            if (item.showFlag != 0) {
              this.coverList1.push(item)
            }
          })
          this.coverList1 = this.coverList1.concat(this.coverList.slice(2))
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    handleVideo(it) {
      this.$router.push({
        path: '/videoplay',
        query: {
          url: it.url,
          title: it.title
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.video-list {
  position: relative;
  > h3 {
    height: 30px;
    font-size: 30px;
    margin: 20px 0;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff4d4d;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    // &:hover {
    //   color: #5e7ed4;
    // }
  }
  .more {
    position: absolute;
    right: 0;
    &:hover {
      color: #5e7ed4;
    }
  }
  .list {
    .cover-list {
      display: flex;
      justify-content: space-between;
    }
    .cover {
      width: 240px;
      .cover-video {
        width: 240px;
        height: 150px;
        background: #000;
        position: relative;
        cursor: pointer;
        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          background: #d8d8d8;
        }
        .player-btn {
          position: absolute;
          left: 10px;
          bottom: 30px;
          width: 16px;
          height: 16px;
          opacity: 1;
        }
        .img-name {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 25px;
          background: rgba(0, 0, 0, 0.2);
          font-size: 10px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          padding-left: 10px;
          color: #ffffff;
          line-height: 25px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    ul {
      width: 100%;
      width: 360px;
      li {
        position: relative;
        width: 100%;
        height: 22px;
        margin: 10px 0;
        padding-left: 25px;
        font-size: 16px;
        font-weight: 400;
        color: #212223;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          color: #5e7ed4;
        }
      }
      li::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 5px;
        width: 20px;
        height: 12px;
        background: url('~@/assets/img/player-icon.png') no-repeat;
      }
    }
  }
}
</style>
