<template>
  <div class="header">
    <header class="header-container">
      <div class="logo">
        <img src="@/assets/img/header_logo.png" @click="backHome" alt="" />
        <div class="layout-title" v-if="layoutTitle">
          {{ layoutTitle || '' }}
        </div>
      </div>
      <div class="search">
        <!-- <div class="login-box">
          <span v-if="userName">您好！{{ userName }}</span>
          <span v-else>
            <span class="login" @click="handle('Login')">您好！请登录</span>
            <span class="reg" @click="handle('reg')">注册</span>
          </span>
        </div> -->
        <a-input-search @search="onSearch">
          <a-button slot="enterButton">
            <a-icon type="search" class="icon-search" />
            搜索
          </a-button>
        </a-input-search>
      </div>
    </header>
    <Modal ref="modal" :type="type" :visible="visible" @close="onClose"></Modal>
  </div>
</template>

<script>
import Modal from '@/components/modal'
import { mapGetters, mapMutations } from 'vuex'
import { onSearch } from '@/api'
export default {
  name: 'Header',
  components: {
    Modal
  },
  data() {
    return {
      visible: false,
      searchData: [],
      type: null
    }
  },
  computed: {
    ...mapGetters({
      layoutTitle: 'layout/lyaoutTitle'
    }),
    userName() {
      return sessionStorage.getItem('username')
    }
  },
  methods: {
    ...mapMutations({
      setLayoutTitle: 'layout/setLayoutTitle'
    }),
    async onSearch(value) {
      console.log(value)
      const params = {
        keyword: value
      }
      const { data, code } = await onSearch(params)
      try {
        if (code === 200) {
          this.searchData = data
          this.$router.push({
            path: '/listpage',
            query: {
              searchData: JSON.stringify(this.searchData)
            }
          })
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    backHome() {
      this.$router.push({
        path: '/'
      })
      this.setLayoutTitle('')
    },
    onClose() {
      this.visible = false
    },
    handle(data) {
      this.visible = true
      this.type = data
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 140px;
  padding: 26px 160px;
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    cursor: pointer;
    display: flex;
    img {
      width: 264px;
      height: 81px;
      object-fit: cover;
      margin-right: 20px;
    }
    .layout-title {
      margin-top: 17px;
      // width: 160px;
      padding: 0 12px;
      height: 64px;
      background: #1766aa;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 64px;
      text-align: center;
    }
  }
  .search {
    text-align: right;
    .ant-input-search {
      width: 406px;
      height: 38px;
      background: #eeeeee;
    }
    .ant-input {
      border: none;
    }
    .ant-input-search-button {
      border: none;
      background: #1766aa;
      color: #fff;
    }
  }
  > span {
    text-align: right;
    display: inline-block;
  }
  .login-box {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .login {
    cursor: pointer;
    &:hover {
      color: #5e7ed4;
    }
  }
  .reg {
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      color: #5e7ed4;
    }
  }
}
</style>
